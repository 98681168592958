<template>
  <div :id="chartId"></div>
</template>

<script>
export default {
  name: "MiniCacheFlowChart",

  props: {
    statements: Array,
    chartId: String,
  },

  data() {
    return {
      chart: null,
      years: [],
    }
  },

  mounted() {
    this.chart = new Highcharts.Chart(this.chartId, this.buildOptions());
  },

  methods: {
    buildOptions() {
      return {
        chart: {
          type: 'column',
        },
        colors: ["#94A3B8", "#64748B", "#334155", "#0EA5E9"],
        credits: {
          enabled: false,
        },
        legend: {
          verticalAlign: 'top',
        },
        navigator: {
          enabled: false,
        },
        title: {
          text: "",
        },
        xAxis: {
          categories: ["営業CF", "投資CF", "財務CF", "フリーCF"],
        },
        yAxis: {
          title: {
            text: "",
          }
        },
        series: this.statements.map((statement) => {
          return {
            name: statement.fiscal_year + "年" + statement.period_end_on + "月期",
            data: [
              parseFloat(statement.operating_cash_flow_in_million_dollar),
              parseFloat(statement.investing_cash_flow_in_million_dollar),
              parseFloat(statement.financing_cash_flow_in_million_dollar),
              parseFloat(statement.free_cash_flow_in_million_dollar),
            ]
          }
        }),
      }
    },
  }
}
</script>

<style scoped>
</style>
