export default {
  methods: {
    toDollarString(number) {
      return new Intl.NumberFormat('ja-JP', {
        style: 'currency',
        currency: 'USD' ,
        maximumFractionDigits: 2
      }).format(number);
    },

    statementMonth(statement) {
      if(statement && statement.statement_date) {
        const date = new Date(statement.statement_date);
        return date.getFullYear() + "年" + (date.getMonth() + 1) + "月"
      }
      return "---"
    },
  }
}
