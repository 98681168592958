<template>
  <div class="flex space-x-2 items-center justify-center bg-white">
    <p class="text-base font-bold leading-normal text-gray-700">{{ name }}</p>
    <select @change="change" class="border rounded-lg border-gray-400">
      <option value="total_revenue_in_million_dollar">売上高</option>
      <option value="net_income_in_million_dollar">純利益</option>
      <option value="operating_income_in_million_dollar">営業利益</option>
      <option value="ordinary_profit_in_million_dollar">経常利益</option>
    </select>
    <p class="text-base font-bold leading-normal text-gray-700">の推移</p>
  </div>
  <div id="financial-statement-chart"></div>
</template>

<script>
export default {
  name: "FinancialStatementChart",

  props: {
    statements: Array,
    name: String,
  },

  data() {
    return {
      chart: null,
      years: [],
      values: [],
      selectedLabel: "売上高",
    }
  },

  mounted() {
    if(this.statements) {
      this.statements.forEach((statement) => {
        this.years.push(statement.fiscal_year);
        this.values.push(parseFloat(statement.total_revenue_in_million_dollar));
      });
    }
    this.chart = new Highcharts.Chart("financial-statement-chart", this.buildOptions());
  },

  methods: {
    change(e) {
      const name = e.target.options[e.target.options.selectedIndex].text;
      this.selectedLabel = name;
      const values = [];
      this.statements.forEach((statement) => {
        values.push(parseFloat(statement[e.target.value]));
      });
      this.values = values;
      this.chart.update({
        yAxis: {
          title: {
            text: this.selectedLabel,
          }
        }
      }, false);

      this.chart.series[0].update({
        name: this.selectedLabel,
        data: this.values,
      })
    },

    buildOptions() {
      return {
        chart: {
          type: 'column',
        },
        credits: {
          enabled: false,
        },
        navigator: {
          enabled: false,
        },
        title: {
          text: "",
        },
        xAxis: {
          categories: this.years,
        },
        yAxis: {
          title: {
            text: this.selectedLabel,
          }
        },
        series: [{
          name: this.selectedLabel,
          data: this.values,
        }],
      }
    },
  }
}
</script>

<style scoped>
</style>
