<template>
  <div class="grid grid-cols-6 items-center min-w-laptop-content md:min-w-[650px] justify-start text-right even:bg-white odd:bg-gray-100">
    <div class="flex items-center justify-center h-full py-2 bg-gray-200 mr-0.5">
      <p class="text-base font-bold leading-normal text-center text-gray-700">日時</p>
    </div>
    <div class="flex items-center justify-center h-full py-2 bg-gray-200 mr-0.5">
      <p class="text-base font-bold leading-normal text-center text-gray-700">始値</p>
    </div>
    <div class="flex items-center justify-center h-full py-2 bg-gray-200 mr-0.5">
      <p class="text-base font-bold leading-normal text-center text-gray-700">高値</p>
    </div>
    <div class="flex items-center justify-center h-full py-2 bg-gray-200 mr-0.5">
      <p class="text-base font-bold leading-normal text-center text-gray-700">安値</p>
    </div>
    <div class="flex items-center justify-center h-full py-2 bg-gray-200 mr-0.5">
      <p class="text-base font-bold leading-normal text-center text-gray-700">終値</p>
    </div>
    <div class="flex items-center justify-center h-full py-2 bg-gray-200">
      <p class="text-base font-bold leading-normal text-center text-gray-700">出来高(株)</p>
    </div>

    <template v-for="(ohlcv, index) in ohlcvs" :key="ohlcv.date">
      <div class="flex items-center justify-center py-2 flex-nowrap" :class="index % 2 == 0 ? 'bg-white' : ''">
        <p class="text-base leading-normal text-center text-gray-700">
          {{ ohlcv.trade_date }}
        </p>
      </div>
      <div class="flex items-center justify-end pr-4 py-2" :class="index % 2 == 0 ? 'bg-white' : ''">
        <p class="text-base leading-normal text-right text-gray-700">
          {{ numberWithPrecision(ohlcv.open_price) }}
        </p>
      </div>
      <div class="flex items-center justify-end pr-4 py-2" :class="index % 2 == 0 ? 'bg-white' : ''">
        <p class="text-base leading-normal text-right text-gray-700">
          {{ numberWithPrecision(ohlcv.high_price) }}
        </p>
      </div>
      <div class="flex items-center justify-end pr-4 py-2" :class="index % 2 == 0 ? 'bg-white' : ''">
        <p class="text-base leading-normal text-right text-gray-700">
          {{ numberWithPrecision(ohlcv.low_price) }}
        </p>
      </div>
      <div class="flex items-center justify-end pr-4 py-2" :class="index % 2 == 0 ? 'bg-white' : ''">
        <p class="text-base leading-normal text-right text-gray-700">
          {{ numberWithPrecision(ohlcv.close_price) }}
        </p>
      </div>
      <div class="flex items-center justify-end pr-4 py-2" :class="index % 2 == 0 ? 'bg-white' : ''">
        <p class="text-base leading-normal text-right text-gray-700">
          {{ ohlcv.volume.toLocaleString() }}
        </p>
      </div>
    </template>
  </div>

  <div class="flex items-center justify-center mt-4 py-2 bg-gray-50 shadow border rounded-lg border-blue-700" @click="loadOhlcvsMore">
    <p class="text-base font-bold leading-normal text-center text-blue-700">
      さらに表示
    </p>
  </div>
</template>

<script>
import NumberFormatter from "./NumberFormatter.js";
import ChartFunctions from "./ChartFunctions.js";

export default {
  name: "OhlcvList",
  
  mixins: [NumberFormatter, ChartFunctions],

  props: {
    ticker: String,
    initOhlcvs: Array,
    iconChevronPath: String,
  },

  data() {
    return {
      ohlcvs: [],
      page: 1,
      per: 20,
    }
  },

  mounted() {
    this.ohlcvs = this.initOhlcvs;
  },

  methods: {
    async loadOhlcvsMore() {
      const nextPage = this.page + 1;
      const path = "/stocks/" + this.ticker + "/historicals.json?page=" + nextPage + "&per=" + this.per;
      const response = await fetch(path);

      if(response.ok) {
        const data = await response.json();
        this.ohlcvs = this.ohlcvs.concat(data);
        this.page = nextPage;
      }
    }
  }
}
</script>

<style scoped>
</style>
