<template>
  <div>
    <div class="text-gray-700 text-xs flex items-center justify-center">期間｜
      <span @click="setRange('3m')" :class="rangeClass('3m')">3ヶ月</span>｜
      <span @click="setRange('6m')" :class="rangeClass('6m')">6ヶ月</span>｜
      <span @click="setRange('1y')" :class="rangeClass('1y')">1年</span>｜
      <span @click="setRange('3y')" :class="rangeClass('3y')">3年</span>｜
      <span @click="setRange('5y')" :class="rangeClass('5y')">5年</span>
    </div>
    <div class="inline-flex items-start justify-between w-full">
      <p class="text-xs leading-none text-center text-gray-700">[{{ name }}]</p>
      <p class="text-xs leading-none text-center text-gray-700">{{ latestDateLabel(this.ohlc) }}</p>
    </div>
    <div id="mini-stock-chart"></div>
  </div>
</template>

<script>
import CookieManager from './CookieManager.vue'
import ChartFunctions from "./ChartFunctions.js";

export default {
  name: "MiniStockChart",

  props: {
    ticker: String,
    name: String,
    ohlcvs: Array,
    highstockUrl: String,
    highstockIndicatorsUrl: String,
    highstockThemeUrl: String,
  },

  mixins: [CookieManager, ChartFunctions],

  data() {
    return {
      ohlc: [],
      volume: [],
      chart: null,
      range: null,
      rangeKey: "mini_chart_range",
      price: null,
    }
  },

  mounted() {
    if (typeof Highcharts === 'undefined') {
      this.asyncLoadChart(
        this.showChart
      );
    } else {
      this.showChart();
    }
  },

  methods: {
    showChart() {
      if (!this.chart) {
        this.setDefaultRange();
        this.chart = new Highcharts.StockChart("mini-stock-chart", this.buildOptions());
        this.chart.showLoading();

        this.loadOHLC(this.range);
      }
    },

    setDefaultRange() {
      this.range = this.getCookieValue(this.rangeKey);
      if(!this.range) {
        this.setRange("3m");
      }
    },

    setRange(range) {
      this.range = range;
      this.setCookie(this.rangeKey, range);
      if(this.chart) {
        this.changeSpan(range);
      }

      const limit = this.limitFromRange(this.range);
      if(this.chart && (limit > this.price.ohlc.length)) {
        this.loadOHLC(this.range);
      }
    },

    async loadOHLC(range) {
      const limit = this.limitFromRange(range);
      const path = "/stocks/" + this.ticker + "/historicals/highcharts.json?limit=" + limit;
      const response = await fetch(path);
      if(response.ok) {
        const json = await response.json();
        this.price = this.toHighChartOHLC(json, true);
        this.ohlc = this.price.ohlc;
        this.chart.update({
          yAxis: {
            min: this.price.low,
          }
        }, false)
        this.chart.hideLoading();
        this.chart.series[0].setData(this.ohlc, false, false);
        this.changeSpan(this.range, this.chart, true);
      }
    },

    buildOptions() {
      return {
        navigator: {
          enabled: false,
        },
        rangeSelector: {
          enabled: false,
        },
        plotOptions: {
          series: {
            dataGrouping: {
              dateTimeLabelFormats: {
                millisecond: [
                  '%A, %b %e, %H:%M:%S.%L', '%A, %b %e, %H:%M:%S.%L', '-%H:%M:%S.%L'
                ],
                second: ['%A, %b %e, %H:%M:%S', '%A, %b %e, %H:%M:%S', '-%H:%M:%S'],
                minute: ['%A, %b %e, %H:%M', '%A, %b %e, %H:%M', '-%H:%M'],
                hour: ['%Y/%m/%d, %H:%M', '-%H:%M'],
                day: ['%Y/%m/%d'],
                week: ['%Y/%m/%d'],
                month: ['%Y/%m'],
                year: ['%Y', '%Y', '-%Y']
              },
            }
          }
        },
        xAxis: {
          type: "datetime",
          dateTimeLabelFormats: {
            millisecond: '%H:%M:%S.%L',
            second: '%H:%M:%S',
            minute: '%H:%M',
            hour: '%H:%M',
            day: '%e. %b',
            week: '%m/%d',
            month: '%y/%m',
            year: '%Y'
          },
          events: {
            afterSetExtremes(e) {
              // Areaの場合のみyAxis.minが効かず0になってしまうので、minを明示的に適用させる
              const option = {
                yAxis: {
                  min: this.chart.series[0].dataMin,
                }
              }
              this.chart.update(option, true, false, false);
            }
          }
        },
        yAxis: {
          endOnTick: false,
          startOnTick: false,
          opposite: false,
          labels: {
            align: "left",
            x: 2,
          }
        },
        tooltip: {
          shared: true,
          split: false,
          xDateFormat: "%Y/%m/%d",
        },
        series: [{
          type: 'area',
          id: this.ticker + '-area',
          name: this.name,
          data: this.ohlc,
          color: "#164E63",
          fillColor: {
            linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
            stops: [
              [0, "#94A3B8"],
              [1, "#ffff"],
            ]
          },
        }, {
          type: 'sma',
          name: "移動平均（25）",
          params: {
            period: 25,
          },
          linkedTo: this.ticker + '-area',
          market: {
            enabled: false
          },
          color: "#DC000C",
        }, {
          type: 'sma',
          name: "移動平均（75）",
          params: {
            period: 75,
          },
          linkedTo: this.ticker + '-area',
          market: {
            enabled: false
          },
          color: "#2563EB",
        }],
        responsive: {
          rules: [{
            condition: {
              maxWidth: 800
            },
            chartOptions: {
              rangeSelector: {
                inputEnabled: false
              }
            }
          }]
        },
        credits: {
          enabled: false
        },
      }
    },
  }
}
</script>

<style scoped>
</style>
