<template>
  <div class="flex flex-row items-start justify-center w-full h-12 relative" id="search-box">
    <div class="basis-3/4 flex flex-1 items-start justify-start h-full py-1 bg-white border-y border-l rounded-tl-lg rounded-bl-lg border-gray-400">
      <input
        type="text"
        class="text-base leading-normal text-gray-500 w-full border-transparent focus:border-transparent focus:ring-0 rounded-lg"
        placeholder="銘柄を検索"
        v-model="searchText">
    </div>
    <div class="w-20 flex items-center justify-center h-full rounded-br-lg rounded-tr-lg bg-gray-700 border border-gray-500">
      <p class="text-base font-bold py-2 px-4 leading-normal text-white" @click="search">検索</p>
    </div>

    <sp-search-stock-result
      :stocks="stocks"
      class="absolute top-12 left-2 ml-2"
      id="search-result"
    ></sp-search-stock-result>
  </div>
</template>

<script>
import SpSearchStockResult from "./SpSearchStockResult.vue"

export default {
  name: "SpSearchStock",

  data() {
    return {
      searchText: "",
      stocks: [],
      minSearchLength: 1,
      isShowResult: false,
    }
  },

  mounted() {
    document.addEventListener('click', (e) => {
      if(this.isShowResult) {
        if(!e.target.closest('#search-result')) {
          this.isShowResult = false
          this.stocks = [];
          this.searchText = "";
        }
      }
    })
  },

  methods: {
    async search() {
      if(this.searchText && (this.searchText.length >= this.minSearchLength)) {
        const path = "/stocks/search.json?keyword=" + this.searchText;
        const response = await fetch(path);

        if(response.ok) {
          const result = await response.json();
          this.stocks = result;
          this.isShowResult = true;
        } else {
          this.stocks = [];
        }
      }
    },
  }
}
</script>

<style scoped>
</style>
