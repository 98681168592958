<template>
  <div v-if="showModal" class="modal-overlay flex items-center justify-center">
    <div class="inline-flex flex-col space-y-4 items-center justify-center shadow rounded-bl-lg rounded-br-lg">
      <div class="inline-flex items-center justify-end w-10/12 md:w-full max-w-[448px]">
        <img @click="showModal = false" class="w-8 h-8 rounded-lg" :src="iconXPath"/>
      </div>
      <div class="flex flex-col space-y-8 items-center justify-center bg-white rounded-lg w-10/12 md:w-full max-w-[448px]">
        <img class="rounded-tl-lg rounded-tr-lg w-full" :src="bannerPath" />
        <div class="flex flex-col space-y-4 items-center justify-start p-4 rounded-bl-lg rounded-br-lg">
          <p class="text-2xl font-bold leading-9 text-gray-700">会員登録（無料）が必要です</p>
          <p class="text-lg leading-relaxed text-gray-700">お気に入りに銘柄を登録すると、基準価額・チャートの一覧表示など便利な機能で、米国株投資における情報を整理してご利用いただけます。</p>
          <a :href="siginUpUrl()"
            class="inline-flex items-center justify-center px-4 py-2 bg-blue-700 shadow rounded-lg w-full">
            <p class="text-lg leading-relaxed text-white">会員登録する（無料）</p>
          </a>
          <a :href="loginUrl()" class="text-lg leading-relaxed text-blue-700">ログイン</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  props: {
    iconXPath: {
      type: String,
      required: true,
    },
    bannerPath: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      showModal: false,
    }
  },

  mounted() {
    window.addEventListener('showRequireRegistrationEvent', this.callback, false);
  },

  methods: {
    loginUrl() {
      const url = location.origin + location.pathname;
      return 'https://id.minkabu.jp/login?callback=' + url;
    },

    siginUpUrl() {
      const url = location.origin + location.pathname;
      return 'https://id.minkabu.jp/sign_up?callback=' + url;
    },

    callback(e) {
      this.showModal = true
    },
  },
};
</script>

<style>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  width: 400px;
  height: 300px;
  background-color: #fff;
  padding: 20px;
}
</style>
