<template>
  <div>
    <div>
      <div class="text-gray-700 text-xs flex items-center justify-center">期間｜
        <span @click="setRange('3m')" :class="rangeClass('3m')">3ヶ月</span>｜
        <span @click="setRange('6m')" :class="rangeClass('6m')">6ヶ月</span>｜
        <span @click="setRange('1y')" :class="rangeClass('1y')">1年</span>｜
        <span @click="setRange('3y')" :class="rangeClass('3y')">3年</span>｜
        <span @click="setRange('5y')" :class="rangeClass('5y')">5年</span>
      </div>
      <div class="inline-flex items-start justify-between w-full">
        <p class="text-xs leading-none text-center text-gray-700">[{{ name }}]</p>
        <p class="text-xs leading-none text-center text-gray-700">{{ latestDateLabel() }}</p>
      </div>
    </div>

    <div id="navi-stock-chart" class="max-h-[213px]"></div>

    <div class="flex flex-col items-center justify-center w-full pt-4">
      <p class="text-xs leading-none text-center text-gray-700">{{ latestDateShortLabel() }}</p>
    </div>

    <div class="flex flex-col space-y-1 items-start justify-center w-full" v-for="(index, i) in indices" :key="index.mic">
      <div class="inline-flex items-center justify-center w-full py-2 border-b border-gray-400">
        <div class="grid grid-cols-3 space-x-2.5 items-center justify-between flex-1 px-0.5 py-0.5">
          <div class="flex space-x-2 items-center justify-start w-24">
            <img :src="index.mic === ticker ? selectedIconPath : notSelectedIconPath" @click="changeIndex(index.mic)" loading="lazy">
            <a :href="indexUrl(index.mic)" class="text-xs leading-none text-blue-700">{{ index.name }}</a>
          </div>
          <p class="text-xs leading-none text-gray-700 text-right pr-2">{{ priceLabel(index) }}</p>
          <p class="text-xs leading-none text-right px-4" :class="index.net_change > 0 ? 'text-red-500' : 'text-blue-400'">{{ netChangeLabel(index.net_change) }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CookieManager from './CookieManager.vue'
import ChartFunctions from "./ChartFunctions.js";

export default {
  name: "NaviStockChart",

  props: {
    ohlcvs: Array,
    indices: Array,
    selectedIconPath: null,
    notSelectedIconPath: null,
  },

  mixins: [CookieManager, ChartFunctions],

  data() {
    return {
      ohlc: [],
      volume: [],
      chart: null,
      highestPrice: null,
      lowestPrice: null,
      name: "",
      ticker: null,
      indicesPrices: {},
      range: null,
      tickerKey: "navi_chart_index_mic",
      rangeKey: "navi_chart_range",
    }
  },

  mounted() {
    this.setDefaultRange();
    this.setDefaultTicker();
    this.name = this.indexName();
    if(this.ohlcvs) {
      const price = this.toHighChartOHLC(this.ohlcvs, true);
      this.indicesPrices[this.ticker] = price;

      const options = this.buildOptions(price);
      this.chart = new Highcharts.StockChart("navi-stock-chart", options);
      this.setRange(this.range);
    }
    //this.loadAllOHLC();
  },

  methods: {
    setDefaultTicker() {
      this.ticker = this.getCookieValue(this.tickerKey);
      if(!this.ticker) {
        this.setTicker(".DJI");
      }
    },

    setTicker(ticker) {
      this.ticker = ticker;
      this.setCookie(this.tickerKey, ticker);
    },

    setDefaultRange() {
      this.range = this.getCookieValue(this.rangeKey);
      if(!this.range) {
        this.setRange("3m");
      }
    },

    setRange(range) {
      this.range = range;
      this.setCookie(this.rangeKey, range);
      if(this.chart) {
        this.changeSpan(range);
      }
    },

    async loadAllOHLC() {
      const targetMics = this.indices.filter((index) => index.mic != this.ticker).map((index) => index.mic);

      targetMics.forEach(async (mic) => {
        this.loadOHLC(mic);
      })
    },

    async loadOHLC(ticker) {
      const path = "/stocks/" + ticker + "/historicals/indices.json";
      const response = await fetch(path);
      if(response.ok) {
        const json = await response.json();
        const price = this.toHighChartOHLC(json, true);
        this.indicesPrices[ticker] = price;
        this.chart.hideLoading();
      }
    },

    hasOHLC(ticker) {
      console.log("!!this.indicesPrices[ticker]", !!this.indicesPrices[ticker]);
      return !!this.indicesPrices[ticker];
    },

    indexName() {
      if(this.indices) {
        const index = this.indices.find((index) => index.mic == this.ticker);
        return index.name
      } else {
        return ""
      }
    },

    async changeIndex(mic) {
      this.setCookie(this.tickerKey, mic);
      this.ticker = mic;
      this.name = this.indexName();

      if (!this.hasOHLC(mic)) {
        this.chart.showLoading();
        await this.loadOHLC(mic);
      }

      const price = this.indicesPrices[mic];
      const options = {
        yAxis: [{
          min: price.low,
          max: price.high,
        }]
      }
      this.chart.series[0].update({
        data: price.ohlc,
        name: this.name,
      }, false);
      this.chart.update(options, false);
      this.changeSpan(this.range, this.chart);
    },

    iconPath(ticker) {
      if(ticker == this.ticker) {
        return this.selectedIconPath
      } else {
        return this.notSelectedIconPath
      }
    },

    latestDateLabel() {
      const ohlc = this.indicesPrices[this.ticker];
      if(ohlc) {
        const date = ohlc.latestDate;
        return date ? date.toLocaleDateString("ja-JP") : ""
      }
      return ""
    },

    latestDateShortLabel() {
      const ohlc = this.indicesPrices[this.ticker];
      if(ohlc) {
        const date = ohlc.latestDate;
        if(date) {
          return (date.getMonth() + 1) + "/" + date.getDate()
        } else {
          return ""
        }
      }
      return ""
    },

    netChangeLabel(change) {
      const precisionNumber = change < 10 ? 10000 : 100;
      return (Math.round(change * precisionNumber) / precisionNumber).toLocaleString("ja-JP", {signDisplay: "always"})
    },

    buildOptions(price) {
      return {
        chart: {
          pinchType: false,
          backgroundColor: null,
          borderWidth: 0,
          height: 213,
          type: 'area',
          margin: [2, 0, 2, 0],
          style: {
            overflow: 'visible'
          },
          // small optimalization, saves 1-2 ms each sparkline
          skipClone: true
        },
        title: {
          text: ''
        },
        credits: {
          enabled: false
        },
        xAxis: {
          labels: {
            enabled: false
          },
          title: {
            text: null
          },
          startOnTick: false,
          endOnTick: false,
          tickPositions: []
        },
        loading: {
          labelStyle: {
            fontWeight: "normal",
          }
        },
        navigator: {
          enabled: false,
        },
        rangeSelector: {
          enabled: false,
        },
        yAxis: {
          endOnTick: false,
          startOnTick: false,
          opposite: false,
          labels: {
            align: 'left',
            x: 2,
          }
        },
        legend: {
          enabled: false
        },
        plotOptions: {
          series: {
            dataGrouping: {
              dateTimeLabelFormats: {
                millisecond: [
                  '%A, %b %e, %H:%M:%S.%L', '%A, %b %e, %H:%M:%S.%L', '-%H:%M:%S.%L'
                ],
                second: ['%A, %b %e, %H:%M:%S', '%A, %b %e, %H:%M:%S', '-%H:%M:%S'],
                minute: ['%A, %b %e, %H:%M', '%A, %b %e, %H:%M', '-%H:%M'],
                hour: ['%Y/%m/%d, %H:%M', '-%H:%M'],
                day: ['%Y/%m/%d'],
                week: ['%Y/%m/%d'],
                month: ['%Y/%m'],
                year: ['%Y', '%Y', '-%Y']
              },
            }
          }
        },
        tooltip: {
          enabled: false
        },
        plotOptions: {
          series: {
            animation: false,
            lineWidth: 1,
            shadow: false,
            states: {
              hover: {
                lineWidth: 1
              }
            },
            marker: {
              radius: 1,
              states: {
                hover: {
                  radius: 2
                }
              }
            },
            fillOpacity: 0.25
          },
          column: {
            negativeColor: '#910000',
            borderColor: 'silver'
          }
        },
        series: [{
          type: 'area',
          id: this.ticker + '-area',
          name: this.name,
          data: price.ohlc,
          pointStart: 1,
          color: "#164E63",
          fillColor: {
            linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
            stops: [
              [0, "#94A3B8"],
              [1, "#ffff"],
            ]
          },
        }, {
          type: 'sma',
          name: "移動平均（25）",
          params: {
            period: 25,
          },
          linkedTo: this.ticker + '-area',
          market: {
            enabled: false
          },
          color: "#DC000C",
        }, {
          type: 'sma',
          name: "移動平均（75）",
          params: {
            period: 75,
          },
          linkedTo: this.ticker + '-area',
          market: {
            enabled: false
          },
          color: "#2563EB",
        }],
      }
    },
  }
}
</script>

<style scoped>
</style>
