export default {
  methods: {
    asyncLoadChart(callback) {
      window.addEventListener("scroll", (event) => {
        this.appendHighchart(callback);
      }, { once: true });
    },

    loadJsLibraries(urls, callback) {
      const url = urls.shift();
      const element = document.createElement('script');
      element.async = false;
      element.src = url;
      const child = document.body.appendChild(element);
      child.onload = () => {
        if(urls.length > 0) {
          this.loadJsLibraries(urls, callback);
        } else {
          this.highchartsGlobalConfig();
          callback();
        }
      }
    },

    appendHighchart(callback) {
      if (typeof Highcharts === 'undefined') {
        const urls = [
          this.highstockUrl,
          this.highstockIndicatorsUrl,
          this.highstockThemeUrl,
        ];

        this.loadJsLibraries(urls, () => {
          callback();
        });
      }
    },

    highchartsGlobalConfig() {
      if (typeof Highcharts !== 'undefined') {
        Highcharts.setOptions({
          lang:{
            thousandsSep: ",",
          }
        });
      }
    },

    limitFromRange(range) {
      let month = 0;

      switch(range) {
        case "1m": month = 1; break;
        case "3m": month = 3; break;
        case "6m": month = 6; break;
        case "1y": month = 12; break;
        case "3y": month = 12 * 3; break;
        case "5y": month = 12 * 5; break;
        case "10y": month = 12 * 10; break;
      }
      return 30 * month
    },

    toHighChartOHLC(ohlcvs, withMetaInfo=false) {
      let ohlc = [],
          i = 0;

      let high = ohlcvs.length > 0 ? ohlcvs[0][2] : [];
      let low = ohlcvs.length > 0 ? ohlcvs[0][3] : [];
      let latestDate = ohlcvs.length > 0 ? new Date(ohlcvs[ohlcvs.length - 1][0]) : null;

      for (i; i < ohlcvs.length; i += 1) {
        ohlc.push([
          ohlcvs[i][0], // the date
          ohlcvs[i][1], // open
          ohlcvs[i][2], // high
          ohlcvs[i][3], // low
          ohlcvs[i][4] // close
        ]);
        if (high < ohlcvs[i][2]) {
          high = ohlcvs[i][2];
        }
        if (low > ohlcvs[i][3]) {
          low = ohlcvs[i][3];
        }
      }

      if(withMetaInfo) {
        return {
          ohlc: ohlc,
          high: high,
          low: low,
          latestDate: latestDate,
        }
      } else {
        return ohlc
      }
    },

    toHighChartVolume(ohlcvs) {
      let volume = [],
          i = 0;

      for (i; i < ohlcvs.length; i += 1) {
        volume.push([
          ohlcvs[i][0], // the date
          ohlcvs[i][5] // the volume
        ]);
      }
      return volume
    },

    rangeClass(range) {
      if (this.range == range) {
        return "bg-blue-500 py-0.5 px-1 rounded text-white text-bold";
      }

      return "";
    },

    changeSpan(range, chart=this.chart, redraw=true) {
      this.range = range;

      let options = {};

      if (range == "ytd") {
        const firstDate = new Date((new Date).getFullYear(), 0, 1, 0, 0, 0);
        const now = new Date();
        options.xAxis = {
          range: now.getTime() - firstDate.getTime()
        }
      } else {
        const days = this.limitFromRange(range)
        options.xAxis = {
          range: days * 24 * 3600 * 1000
        }
      }

      chart.update(options, redraw, false, false);
      let extremes = chart.yAxis[0].getExtremes();
      chart.yAxis[0].update({min: extremes.dataMin * 0.95, max: extremes.dataMax * 1.05}, true);
    },

    numberWithPrecision(num, precision=2) {
      const numbers = String(num).split('.');
      let text = String(numbers[0]).replace( /(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
      if (numbers.length > 1) {
        const decimalFraction = Math.floor(Number("0." + numbers[1]) * 10 ** precision)/10 ** precision;
        let fraction = String(decimalFraction).split('.')[1];
        if(fraction) {
          if(fraction.length == 1) {
            fraction += "0";
          }
          text += '.' + fraction;
        } else {
          text += '.' + ( Array(precision+1).join('0')).slice(-precision);
        }
      }
      return text;
    },

    priceLabel(index) {
      if(index.price >= 10) {
        // 2桁
        return this.numberWithPrecision(index.price)
      } else {
        // 4桁
        return this.numberWithPrecision(index.price, 4)
      }
    },

    latestDateLabel(ohlc) {
      if (ohlc && ohlc.length > 0) {
        const date = new Date(ohlc[ohlc.length - 1][0]);
        return date.toLocaleDateString("ja-JP")
      } else {
        return ""
      }
    },

    indexUrl(mic) {
      switch(mic) {
        case "JPY=X":
          return "https://fx.minkabu.jp/pair/USDJPY"
        case "EUR=X":
          return "https://fx.minkabu.jp/pair/EURUSD"
        case ".TOPX":
          return "https://minkabu.jp/stock/KSISU1000"
        case ".N225":
          return "https://minkabu.jp/stock/100000018"
        case ".DJI":
          return "https://us.kabutan.jp/indexes/%5EDJI/"
        case ".IXIC":
          return "https://us.kabutan.jp/indexes/%5EIXIC/"
        case ".SPX":
          return "https://us.kabutan.jp/indexes/%5ESPX/"
        default:
          return "/stocks/" + mic
      }
    },
  }
}
