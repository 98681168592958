<template>
  <div :id="chartId"></div>
</template>

<script>
export default {
  name: "MiniProfitChart",

  props: {
    chartId: String,
    years: Array,
    roes: Array,
    roas: Array,
  },

  data() {
    return {
      chart: null,
    }
  },

  mounted() {
    this.chart = new Highcharts.Chart(this.chartId, this.buildOptions());
  },

  methods: {
    buildOptions() {
      return {
        chart: {
          type: 'line',
          height: (9 / 16 * 100) + '%',
        },
        colors: ["#06b6d4", "#fbbf24"],
        credits: {
          enabled: false,
        },
        navigator: {
          enabled: false,
        },
        title: {
          text: "",
        },
        xAxis: {
          categories: this.years,
        },
        yAxis: {
          title: {
            text: "",
          }
        },
        series: [{
          name: 'ROE',
          data: this.roes.map(x => parseFloat(x)),
        }, {
          name: 'ROA',
          data: this.roas.map(x => parseFloat(x)),
        }],
      }
    },
  }
}
</script>

<style scoped>
</style>
