<template>
  <div class="overflow-x-auto">
    <div class="w-[700px] md:w-full grid grid-cols-6 text-base leading-normal text-center text-gray-700 pt-4 bg-white">
      <p class="font-bold bg-gray-100 py-2 mr-1">決算期</p>
      <p class="font-bold bg-gray-100 py-2 mr-1">売上高</p>
      <p class="font-bold bg-gray-100 py-2 mr-1">営業利益</p>
      <p class="font-bold bg-gray-100 py-2 mr-1">経常利益</p>
      <p class="font-bold bg-gray-100 py-2 mr-1">純利益</p>
      <p class="font-bold bg-gray-100 py-2">1株益</p>

      <template v-for="(statement, index) in statements.slice(0, 5)" :key="index">
      <div class="inline-flex items-center justify-center border-b border-gray-200" :class="index == 0 ? 'border-t' : ''">
        <p class="grow py-2 bg-gray-100">{{ statementMonth(statement) }}</p>
        <p class="py-2" :class="backgroundColor(index)">&nbsp;</p>
      </div>
      <p class="border-b border-gray-200 py-2" :class="backgroundColor(index)">
        {{ totalRevenue(statement, index) }}
      </p>
      <p class="border-b border-gray-200 py-2" :class="backgroundColor(index)">
        {{ operatingIncome(statement) }}
      </p>
      <p class="border-b border-gray-200 py-2" :class="backgroundColor(index)">
        {{ ordinayProfit(statement) }}
      </p>
      <p class="border-b border-gray-200 py-2" :class="backgroundColor(index)">
        {{ netIncome(statement) }}
      </p>
      <p class="border-b border-gray-200 py-2" :class="backgroundColor(index)">
        {{ eps(statement) }}
      </p>
      </template>
    </div>
  </div>
</template>

<script>
import NumberFormater from "./NumberFormatter.js"

export default {
  name: "QuaterlyFinancialStatementTable",

  mixins: [NumberFormater],

  props: {
    statements: Array,
    mode: String, // accumulation or single
    hideEps: Boolean,
  },

  data() {
    return {
      formatter: Intl.NumberFormat('en-US'),
    }
  },

  methods: {
    totalRevenue(statement) {
      if(this.mode == "accumulation") {
        return this.formatter.format(statement.cumulative_total_revenue_in_million_dollar)
      } else {
        return this.formatter.format(statement.total_revenue_in_million_dollar)
      }
    },

    operatingIncome(statement) {
      if(this.mode == "accumulation") {
        return this.formatter.format(statement.cumulative_operating_income_in_million_dollar)
      } else {
        return this.formatter.format(statement.operating_income_in_million_dollar)
      }
    },

    ordinayProfit(statement) {
      if(this.mode == "accumulation") {
        return this.formatter.format(statement.cumulative_ordinary_profit_in_million_dollar)
      } else {
        return this.formatter.format(statement.ordinary_profit_in_million_dollar)
      }
    },

    netIncome(statement) {
      if(this.mode == "accumulation") {
        return this.formatter.format(statement.cumulative_net_income_in_million_dollar)
      } else {
        return this.formatter.format(statement.net_income_in_million_dollar)
      }
    },

    eps(statement) {
      if(this.hideEps) {
        return '---'
      }

      if(this.mode == "accumulation") {
        return this.formatter.format(statement.cumulative_eps)
      } else {
        return this.formatter.format(statement.eps)
      }
    },

    backgroundColor(index) {
      if( index == 0 ) {
        return 'border-t bg-yellow-200'
      } else if (index == 4) {
        return 'bg-yellow-100'
      }
    },
  }
}
</script>

<style scoped>
</style>
