<template>
  <template v-for="favorite in state.favoriteList" :key="favorite.ticker">
    <div class="inline-flex space-x-0.5 items-center justify-start bg-white w-full">
      <div class="flex flex-col items-start justify-start px-4 py-2 bg-white w-5/12">
        <div class="inline-flex w-items-center justify-start bg-white w-full">
          <a :href="stockPath(favorite)" class="text-xs leading-none text-gray-500">
            {{ favorite.ticker }} {{ favorite.stock.exchange_name }}
          </a>
        </div>
        <div class="inline-flex items-center justify-start bg-white">
          <a :href="stockPath(favorite)" class="text-base font-bold leading-normal text-blue-700">{{ favorite.stock.company_name }}</a>
        </div>
      </div>
      <div class="inline-flex flex-col items-end justify-start p-2 bg-white w-2/12">
        <p>{{ priceLabel(favorite) }}</p>
        <p class="text-xs leading-none" :class="priceColorClass(favorite)">{{ priceChangeLabel(favorite) }} ({{ priceChangeRatioLabel(favorite) }}%)</p>
      </div>
      <div class="inline-flex flex-col items-center justify-center h-full px-4 py-2 bg-white w-3/12">
        <div class="inline-flex items-start justify-start px-2 py-1 rounded w-12" :class="researchColorClass(favorite)" >
          <p class="text-base font-bold leading-normal text-white w-full">{{ researchLabel(favorite) }}</p>
        </div>
      </div>
      <div class="flex items-center justify-center h-full p-4 bg-white w-2/12">
        <button
          class="text-base font-bold leading-normal text-blue-700"
          @click.prevent="deleteFavorite(favorite.ticker)">
        削除
        </button>
      </div>
    </div>
  </template>
</template>

<script>
import { defineProps, reactive } from 'vue';
import ChartFunctions from "./ChartFunctions.js";

export default {
  name: "FavoriteList",

  mixins: [ChartFunctions],

  props: {
    favorites: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
    }
  },

  setup(props) {
    const state = reactive({
      favoriteList: props.favorites,
    });

    const deleteFavorite = async (ticker) => {
      const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

      const url = `/favorites/${ticker}`;
      const response = await fetch(`/favorites/${ticker}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-TOKEN" : csrfToken,
        }
      })

      const data = await response.json();
      if(response.status === 200) {
        state.favoriteList = state.favoriteList.filter((item) => item.ticker !== ticker);
      } else if(response.status === 500) {
        alert(ticker + "のお気に入りの削除中にエラーが発生しました。しばらく経ってから再度お試しください。");
      }
    }

    return { state, deleteFavorite };
  },

  methods: {
    stockPath(favorite) {
      return '/stocks/' + favorite.ticker;
    },

    priceColorClass(favorite) {
      if(favorite.price == undefined || favorite.price.close_price == undefined) {
        return "";
      } else {
        return favorite.price.prev_price_diff < 0 ? "text-blue-400" : "text-red-400";
      }
    },

    researchColorClass(favorite) {
      if(favorite.research == undefined || favorite.research.rating == undefined) {
        return "";
      } else {
        return "bg-mk-" + favorite.research.rating
      }
    },


    priceLabel(favorite) {
      if(favorite.price && favorite.price.close_price) {
        return this.numberWithPrecision(favorite.price.close_price);
      } else {
        return "---";
      }
      return
    },

    priceChangeLabel(favorite) {
      let text = "";
      if(favorite.price && favorite.price.prev_price_diff) {
        return this.numberWithPrecision(favorite.price.prev_price_diff);
      } else {
        return "---";
      }
    },

    priceChangeRatioLabel(favorite) {
      if(favorite.price && favorite.price.prev_price_ratio) {
        return this.numberWithPrecision(favorite.price.prev_price_ratio);
      } else {
        return "---";
      }
    },

    researchLabel(favorite) {
      if(favorite.research && favorite.research.rating) {
        switch(favorite.research.rating) {
          case "buy": return "買い"; break;
          case "sell": return "売り"; break;
          default: return "---"; break;
        }
      } else {
        return "---";
      }
    },
  }
}
</script>

<style scoped>
</style>
