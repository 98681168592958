<template>
  <div class="flex flex-col space-y-4">
    <div class="flex flex-row text-gray-700 text-xs items-center justify-end">期間｜
      <span @click="setRange('3m')" :class="rangeClass('3m')">3ヶ月</span>｜
      <span @click="setRange('6m')" :class="rangeClass('6m')">6ヶ月</span>｜
      <span @click="setRange('1y')" :class="rangeClass('1y')">1年</span>｜
      <span @click="setRange('3y')" :class="rangeClass('3y')">3年</span>｜
      <span @click="setRange('5y')" :class="rangeClass('5y')">5年</span>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-2 items-center justify-between">
      <template v-for="chartData in dataSet" :key="chartData.id">
        <div class="flex flex-col w-full p-2 basis-1/2 rounded bg-white">
          <div class="inline-flex items-center justify-between w-full">
            <a :href="indexUrl(chartData.ticker)" class="text-lg font-bold leading-relaxed px-2 pt-2 text-blue-700 text-left">{{ chartData.name }}</a>
            <p class="text-sm leading-tight text-right">{{ dateLabel(chartData.datetime) }}</p>
          </div>
          <div class="px-2">
            <p class="text-lg font-bold leading-relaxed text-gray-700 text-right">
              {{ priceLabel(chartData) }}
            </p>
            <p class="text-sm leading-tight text-red-500 text-right">
              {{ changeLabel(chartData) }}
            </p>
          </div>
          <div :id="chartData.id"></div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import CookieManager from './CookieManager.vue'
import ChartFunctions from "./ChartFunctions.js";

export default {
  name: "FlexMiniStockCharts",

  props: {
    dataSet: Array,
  },

  mixins: [ChartFunctions, CookieManager],

  data() {
    return {
      charts: [],
      range: null,
      rangeKey: "flex_charts_range",
    }
  },

  mounted() {
    this.setDefaultRange();
    this.dataSet.forEach((data) => {
      const ohlcInfo = this.toHighChartOHLC(data.ohlcvs, true);
      data.ohlc = ohlcInfo.ohlc;
      data.high = ohlcInfo.high;
      data.low = ohlcInfo.low;
      const options = this.buildOptions(data);
      const chart = new Highcharts.StockChart(data.id, options);
      this.changeSpan(this.range, chart);
      this.charts.push(chart);
    });
  },

  methods: {
    setDefaultRange() {
      this.range = this.getCookieValue(this.rangeKey);
      if(!this.range) {
        this.setRange("3m");
      }
    },

    setRange(range) {
      this.range = range;
      this.setCookie(this.rangeKey, range);
      this.charts.forEach((chart) => {
        this.changeSpan(range, chart);
      })
    },

    dateLabel(timestamp) {
      if (timestamp) {
        const date = new Date(timestamp);
        return date.toLocaleDateString("ja-JP")
      } else {
        return ""
      }
    },

    changeLabel(chartData) {
      const precisionNumber = chartData.price < 10 ? 4 : 2;
      const netChange = this.numberWithPrecision(chartData.net_change, precisionNumber);
      const percentChange = this.numberWithPrecision(chartData.percent_change, 2);

      return netChange + "(" + percentChange + "%)"
    },

    buildOptions(data) {
      return {
        chart: {
          height: (9 / 16 * 100) + '%',
        },
        navigator: {
          enabled: false,
        },
        rangeSelector: {
          enabled: false,
        },
        plotOptions: {
          series: {
            dataGrouping: {
              dateTimeLabelFormats: {
                millisecond: [
                  '%A, %b %e, %H:%M:%S.%L', '%A, %b %e, %H:%M:%S.%L', '-%H:%M:%S.%L'
                ],
                second: ['%A, %b %e, %H:%M:%S', '%A, %b %e, %H:%M:%S', '-%H:%M:%S'],
                minute: ['%A, %b %e, %H:%M', '%A, %b %e, %H:%M', '-%H:%M'],
                hour: ['%Y/%m/%d, %H:%M', '-%H:%M'],
                day: ['%Y/%m/%d'],
                week: ['%Y/%m/%d'],
                month: ['%Y/%m'],
                year: ['%Y', '%Y', '-%Y']
              },
            }
          }
        },
        xAxis: {
          type: "datetime",
          dateTimeLabelFormats: {
            millisecond: '%H:%M:%S.%L',
            second: '%H:%M:%S',
            minute: '%H:%M',
            hour: '%H:%M',
            day: '%e. %b',
            week: '%m/%d',
            month: '%y/%m',
            year: '%Y'
          },
        },
        yAxis: {
          endOnTick: false,
          startOnTick: false,
          min: data.low,
          opposite: false,
          labels: {
            x: 25
          }
        },
        tooltip: {
          enabled: false,
        },
        series: [{
          type: 'area',
          id: data.ticker + '-area',
          name: data.name,
          data: data.ohlc,
          color: "#164E63",
          fillColor: {
            linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
            stops: [
              [0, "#94A3B8"],
              [1, "#ffff"],
            ]
          },
        }],
        responsive: {
          rules: [{
            condition: {
              maxWidth: 800
            },
            chartOptions: {
              rangeSelector: {
                inputEnabled: false
              }
            }
          }]
        },
        credits: {
          enabled: false
        },
      }
    },
  }
}
</script>

<style scoped>
</style>
