import { createApp } from 'vue/dist/vue.esm-bundler';
import '@fortawesome/fontawesome-free/css/all.min.css';

import CardStockChart from '../components/CardStockChart.vue'
import FavoriteList from '../components/FavoriteList.vue'
import FavoriteToggleButton from '../components/FavoriteToggleButton.vue'
import FinancialStatementChart from '../components/FinancialStatementChart.vue'
import FlexMiniStockCharts from '../components/FlexMiniStockCharts.vue'
import MiniCashFlowChart from '../components/MiniCashFlowChart.vue'
import MiniFinancialStatementChart from '../components/MiniFinancialStatementChart.vue'
import MiniProfitChart from '../components/MiniProfitChart.vue'
import MiniStockChart from '../components/MiniStockChart.vue'
import NaviStockChart from '../components/NaviStockChart.vue'
import NewsList from '../components/NewsList.vue'
import OhlcvList from '../components/OhlcvList.vue'
import QuaterlyFinancialStatementTable from '../components/QuaterlyFinancialStatementTable.vue'
import RequireRegistrationModalView from '../components/RequireRegistrationModalView.vue'
import SpSearchStockResult from '../components/SpSearchStockResult.vue'
import SpSearchStock from '../components/SpSearchStock.vue'
import StockChart from '../components/StockChart.vue'
import SlidingDisclaimer from '../components/SlidingDisclaimer.vue'

const app = createApp({
  data() {
    return {
      statementTableMode: "accumulation", // or single
    }
  },
  methods: {
    setStatementTableMode(mode) {
      this.statementTableMode = mode;
    }
  },
})

app.component('CardStockChart', CardStockChart);
app.component('FavoriteList', FavoriteList);
app.component('FavoriteToggleButton', FavoriteToggleButton);
app.component('FinancialStatementChart', FinancialStatementChart);
app.component('FlexMiniStockCharts', FlexMiniStockCharts);
app.component('MiniCashFlowChart', MiniCashFlowChart);
app.component('MiniFinancialStatementChart', MiniFinancialStatementChart);
app.component('MiniProfitChart', MiniProfitChart);
app.component('MiniStockChart', MiniStockChart);
app.component('NaviStockChart', NaviStockChart);
app.component('NewsList', NewsList);
app.component('OhlcvList', OhlcvList);
app.component('QuaterlyFinancialStatementTable', QuaterlyFinancialStatementTable);
app.component('RequireRegistrationModalView', RequireRegistrationModalView);
app.component('SpSearchStockResult', SpSearchStockResult);
app.component('SpSearchStock', SpSearchStock);
app.component('SlidingDisclaimer', SlidingDisclaimer);
app.component('StockChart', StockChart);

app.mount('#app');
