<template>
  <template v-if="mode == 'sp'">
    <button @click="toggleFavorite" class="w-32 h-32 z-50">
      <img :src="iconFavoritePath()" class="w-full h-full" alt="お気に入り">
    </button>
  </template>
  <template v-else-if="mode == 'stock_navi'">
    <div class="w-28 inline-flex space-x-2 items-center justify-start p-2 border rounded border-white" :class="isFavorite ? 'bg-blue-400' : ''">
      <button @click="toggleFavorite" class="inline-flex items-center space-x-1 w-full flex-1 rounded-lg">
        <img :src="iconFavoritePath()" class="w-[18px] h-[16px]" alt="お気に入り">
        <p class="text-sm leading-tight text-white">お気に入り</p>
      </button>
    </div>
  </template>
  <template v-else>
    <div @click="toggleFavorite" class="inline-flex items-center justify-center space-x-2">
      <img :src="iconFavoritePath()" alt="お気に入り">
      <p class="text-sm leading-tight text-gray-500">お気に入り</p>
    </div>
  </template>
</template>

<script>
export default {
  name: "FavoriteToggleButton",

  props: {
    ticker: {
      type: String,
      required: true
    },
    isActive: {
      type: Boolean,
      required: true
    },
    mode: {
      type: String,
      required: true,
      default: "stock_navi", // stock_navi, sp, card
    },
    iconFavoriteInactivePath: {
      type: String,
      required: true
    },
    iconFavoriteActivePath: {
      type: String,
      required: true
    },
  },

  data() {
    return {
      isFavorite: false,
      csrfToken: null,
    }
  },

  mounted() {
    this.csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    this.isFavorite = this.isActive;
  },

  methods: {
    iconFavoritePath() {
      return this.isFavorite ? this.iconFavoriteActivePath : this.iconFavoriteInactivePath;
    },

    toggleFavorite() {
      if(this.isFavorite) {
        this.removeFavorite(this.ticker);
      } else {
        this.addFavorite(this.ticker);
      }
    },

    async addFavorite(ticker) {
      const params = {
        ticker: ticker,
      }

      const response = await fetch("/favorites", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-TOKEN" : this.csrfToken,
        },
        body: JSON.stringify(params),
      });
      if (response.ok) {
        this.isFavorite = true
      } else {
        const event = new Event('showRequireRegistrationEvent');
        window.dispatchEvent(event);
      }
    },

    async removeFavorite(ticker) {
      const params = {
        ticker: ticker,
      }
      const response = await fetch(`/favorites/${ticker}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-TOKEN" : this.csrfToken,
        },
        body: JSON.stringify({})
      });
      if (response.ok) {
        this.isFavorite = false
      }
    },
  }
}
</script>

<style scoped>
</style>
