<template>
  <div :id="chartId()" class="w-full" :class="height()"></div>
</template>

<script>
import ChartFunctions from "./ChartFunctions.js";

export default {
  name: "CardStockChart",

  props: {
    ticker: String,
    ohlcvs: Array,
    withAxis: {
      type: Boolean,
      default: false,
    },
  },

  mixins: [ChartFunctions],

  data() {
    return {
      ohlc: [],
      chart: null,
    }
  },

  mounted() {
    if(this.ohlcvs) {
      this.price = this.toHighChartOHLC(this.ohlcvs, true);
      this.ohlc = this.price.ohlc;
      const options = this.buildOptions();
      this.chart = new Highcharts.StockChart(this.chartId(), options);
    }
  },

  methods: {
    chartId() {
      return "card-stock-chart-" + this.ticker
    },

    height() {
      return this.withAxis ? "h-32" : "h-20"
    },

    buildOptions() {
      return {
        navigator: {
          enabled: false,
        },
        rangeSelector: {
          enabled: false,
        },
        xAxis: {
          visible: this.withAxis,
          dateTimeLabelFormats: {
            millisecond: '%H:%M:%S.%L',
            second: '%H:%M:%S',
            minute: '%H:%M',
            hour: '%H:%M',
            day: '%e. %b',
            week: '%m/%d',
            month: '%y/%m',
            year: '%Y'
          },
        },
        yAxis: {
          visible: this.withAxis,
          endOnTick: this.withAxis,
          startOnTick: this.withAxis,
          opposite: this.withAxis,
          min: this.price.low,
          offset: 25,
          labels: {
            enabled: this.withAxis,
          }
        },
        tooltip: {
          enabled: false,
        },
        series: [{
          type: 'area',
          id: this.ticker + '-area',
          name: this.name,
          data: this.ohlc,
          color: "#164E63",
          fillColor: {
            linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
            stops: [
              [0, "#94A3B8"],
              [1, "#ffff"],
            ]
          },
        }],
        credits: {
          enabled: false
        },
      }
    },
  }
}
</script>

<style scoped>
</style>
