<template>
  <div>
    <div class="flex items-center my-4 space-x-2">
      <label for="news-filter" class="text-base font-bold leading-normal text-center text-gray-700">絞り込み</label>

      <select name="news" id="news-filter" class="w-24 h-8 text-xs bg-white border rounded-lg border-gray-400" v-model="selected">
        <option value="all">全て</option>
        <option value="market_summary">市況</option>
        <option value="factor">材料</option>
        <option value="notice">注目</option>
        <option value="technical">テク</option>
      </select>
    </div>

    <div class="w-full">
      <div class="rounded-lg bg-white py-1">
        <div v-for="news in currentNewsList" :key="news.news_id">
          <div class="pt-4 px-2 mx-4 border-b border-gray-400">
            <div class="pl-2 pr-8 pt-2 text-base text-blue-700">
              {{ news.headline }}
            </div>
            <div class="grid grid-cols-2 place-content-between p-2">
              <div class="text-left text-sm">
                <span class="px-1 text-white bg-gray-400 rounded">レポート</span>
                <span class="mx-2 text-gray-500 hidden md:inline">配信元：{{ news.provider }}</span>
              </div>
              <div class="text-right text-sm">
                <span v-if="isNew(news)" class="text-red-500">NEW</span>
                <span class="text-gray-500">{{ timeLabel(news) }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="m-4 flex items-center justify-center p-2.5 bg-gray-100 border rounded-lg border-gray-400">
          <img :src="iconChevronPath" class="mx-2" loading="lazy">
          <p class="text-sm font-bold text-center text-blue-900">ニュース一覧</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewsList",

  props: {
    newsList: Array,
    iconChevronPath: String,
    ticker: String,
  },

  data() {
    return {
      currentNewsList: [],
      selected: "all",
    }
  },

  watch: {
    async selected(newSelected, oldSelected) {
      if (newSelected != oldSelected) {
        this.currentNewsList = await this.fetchNewsByCategory(newSelected);
      }
    }
  },

  created() {
    this.currentNewsList = this.newsList;
  },

  methods: {
    async fetchNewsByCategory(category) {
      const path = "/news_categories/" + category;
      const response = await fetch(path);
      if(response.ok) {
        return await response.json()
      }
      return []
    },

    timeLabel(news) {
      const creationTime = new Date(Date.parse(news.creation_time));
      const today = new Date();

      // Today
      if(today.getDate() == creationTime.getDate()) {
        return "本日 " + today.getHours() + ":" + today.getMinutes()
      }
      // This year
      else if(today.getFullYear() == creationTime.getFullYear()) {
        return (today.getMonth() + 1) + "/" + today.getDate() + " " + today.getHours() + ":" + today.getMinutes()
      }
      // Before this year
      else {
        return today.toLocaleDateString("ja-JP")
      }

      return creationTime < today
    },

    isNew(news) {
      const creationTime = Date.parse(news.creation_time);
      const today = new Date();
      today.setHours(-6);

      return creationTime > today
    },
  }
}
</script>

<style scoped>
</style>
