<script>  
export default {  
  methods: {
    getCookieValue(name) {
      const rawValue = this.getCookie(name);
      if(rawValue) {
        let value = rawValue.split("=")[1];
        value = decodeURIComponent(value);
        return value
      } else {
        return null
      }
    },

    getCookie(name) {
      let cookies = this.getCookies(name);
      return cookies[0];
    },

    getCookies(name) {
      let cookies = document.cookie.split("; ");
      return cookies.filter(cookie => cookie.startsWith(`${name}=`));
    },

    deleteDuplicateCookie(name) {
      let duplicateCookies = this.getCookies(name).length > 1;
      if (duplicateCookies) {
        document.cookie = `${name}=; expires=${new Date(0)}`;
      }
    },

    setCookie(name, value, expiresAt) {
      this.deleteDuplicateCookie(name);
      value = encodeURIComponent(value);
      if(expiresAt) {
        let cookieExpiredAt = expiresAt.toUTCString();
        return document.cookie = `${name}=${value}; expires=${cookieExpiredAt}; path=/`;
      } else {
        return document.cookie = `${name}=${value}; path=/`;
      }
    }
  }
}  
</script>  
