<template>
  <div :id="chartId"></div>
</template>

<script>
export default {
  name: "MiniFinancialStatementChart",

  props: {
    statements: Array,
    label: String,
    chartId: String,
    attribute: String,
  },

  data() {
    return {
      chart: null,
      years: [],
      values: [],
    }
  },

  mounted() {
    if(this.statements) {
      this.statements.forEach((statement) => {
        this.years.push(statement.fiscal_year);
        this.values.push({y: parseFloat(statement[this.attribute]), color: "#D4D4D8"});
      });
      const latestValue = this.values.pop();
      this.values.push({y: latestValue["y"], color: "#0EA5E9"});
    }
    this.chart = new Highcharts.Chart(this.chartId, this.buildOptions());
  },

  methods: {
    buildOptions() {
      return {
        chart: {
          type: 'column',
          height: (9 / 16 * 100) + '%',
        },
        credits: {
          enabled: false,
        },
        navigator: {
          enabled: false,
        },
        legend: {
          enabled: false,
        },
        title: {
          text: "",
        },
        plotOptions: {
          series: {
          }
        },
        xAxis: {
          categories: this.years,
        },
        yAxis: {
          title: {
            text: "",
          }
        },
        series: [{
          name: this.label,
          data: this.values,
        }],
      }
    },
  }
}
</script>

<style scoped>
</style>
