<template>
  <div>
    <div class="flex items-center justify-between px-4">
      <div class="text-gray-700 text-2xs md:text-xs flex items-center justify-center">期間｜
        <span @click="setRange('1m')" class="px-1" :class="rangeClass('1m')">1ヶ月</span>｜
        <span @click="setRange('3m')" class="px-1" :class="rangeClass('3m')">3ヶ月</span>｜
        <span @click="setRange('6m')" class="px-1" :class="rangeClass('6m')">6ヶ月</span>｜
        <span @click="setRange('ytd')" class="px-1" :class="rangeClass('ytd')">YTD</span>｜
        <span @click="setRange('1y')" class="px-1" :class="rangeClass('1y')">1年</span>｜
        <span @click="setRange('5y')" class="px-1" :class="rangeClass('5y')">5年</span>｜
        <span @click="setRange('10y')" class="px-1" :class="rangeClass('10y')">10年</span>
      </div>

      <div class="flex space-x-1 md:space-x-4 items-center">
        <div class="inline-flex items-center justify-center w-6 h-6 p-1 rounded-sm" :class="chartType == 'area' ? 'bg-blue-900' : ''">
          <img @click="onAreaChartIcon" :src="chartType == 'area' ? iconAreaChartWhitePath : iconAreaChartBlackPath" loading="lazy">
        </div>
        <div class="inline-flex items-center justify-center w-6 h-6 p-1 rounded-sm" :class="chartType == 'candlestick' ? 'bg-blue-900' : ''">
          <img @click="onCandleChartIcon" :src="chartType == 'candlestick' ? iconCandleChartWhitePath : iconCandleChartBlackPath" loading="lazy">
        </div>
      </div>
    </div>

    <div class="inline-flex space-x-2.5 items-center justify-between ml-4">
      <div class="flex space-x-6 items-center justify-start pt-2">
        <div class="flex space-x-1 justify-start">
          <img :src="chartLegendCyanPath" loading="lazy">
          <p class="text-xs leading-none text-center text-gray-700">{{ name }}</p>
        </div>
        <div class="flex space-x-1 items-center justify-start">
          <img :src="chartLegendRedPath" loading="lazy">
          <p class="text-xs leading-none text-center text-gray-700">移動平均(25)</p>
        </div>
        <div class="flex space-x-1 items-center justify-start">
          <img :src="chartLegendBluePath" loading="lazy">
          <p class="text-xs leading-none text-center text-gray-700">移動平均(75)</p>
        </div>
      </div>
    </div>
    <div id="stock-chart"></div>
  </div>
</template>

<script>
import CookieManager from './CookieManager.vue'
import ChartFunctions from "./ChartFunctions.js";

export default {
  name: "StockChart",

  props: {
    ticker: String,
    name: String,
    ohlcvs: Array,
    iconAreaChartWhitePath: String,
    iconAreaChartBlackPath: String,
    iconCandleChartWhitePath: String,
    iconCandleChartBlackPath: String,
    chartLegendCyanPath: String,
    chartLegendRedPath: String,
    chartLegendBluePath: String,
  },

  mixins: [ChartFunctions, CookieManager],

  data() {
    return {
      ohlc: [],
      volume: [],
      chart: null,
      chartType: null, // area or candlestick
      range: null,
      rangeKey: "stock_chart_range",
      chartTypeKey: "stock_chart_chart_type",
      price: null,
      isLoadedTenYearsOHLC: false,
    }
  },

  mounted() {
    this.setDefaultRange();
    this.setDefaultChartType();

    if(this.ohlcvs) {
      this.price = this.toHighChartOHLC(this.ohlcvs, true);
      this.ohlc = this.price.ohlc;
      this.volume = this.toHighChartVolume(this.ohlcvs);
      const options = this.buildOptions();
      let self = this;

      this.chart = new Highcharts.StockChart("stock-chart", options);
      this.changeSpan(this.range, this.chart, true);
      if(this.range == "10y") {
        this.isLoadedTenYearsOHLC = true;
      }
    }
    if(this.isLoadedTenYearsOHLC == false) {
      this.loadAllOHLC();
    }
  },

  methods: {
    async loadAllOHLC() {
      const path = "/stocks/" + this.ticker + "/historicals/highcharts.json?limit=2600";
      const response = await fetch(path);
      if(response.ok) {
        const json = await response.json();
        this.price = this.toHighChartOHLC(json, true);
        this.ohlc = this.price.ohlc;
        this.chart.series[0].setData(this.ohlc, false, false);
        this.changeSpan(this.range, this.chart);
      }
      this.isLoadedTenYearsOHLC = true;
    },

    setDefaultRange() {
      this.range = this.getCookieValue(this.rangeKey);
      if(!this.range) {
        this.setRange("1y");
      }
    },

    setDefaultChartType() {
      this.chartType = this.getCookieValue(this.chartTypeKey);
      if(!this.chartType) {
        this.setChartType("area");
      }
    },

    setRange(range) {
      this.range = range;
      this.setCookie(this.rangeKey, range);
      if(this.chart) {
        this.changeSpan(range);
      }
    },

    setChartType(chartType) {
      this.chartType = chartType;
      this.setCookie(this.chartTypeKey, chartType);
    },

    onAreaChartIcon(e) {
      this.setChartType("area");
      const options = {
        type: this.chartType
      }
      this.chart.series[0].update(options, true, false, false);
    },

    onCandleChartIcon(e) {
      this.setChartType("candlestick");
      const options = {
        type: this.chartType
      }
      this.chart.series[0].update(options, true, false, false);
    },

    buildOptions() {
      return {
        chart: {
          animation: false,
          styledMode: false,
        },
        rangeSelector: {
          enabled: false,
        },
        xAxis: [{  // X軸
          labels: {
            formatter: function(){
              let date = new Date();
              date.setTime(this.value);
              return date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate();
            }
          },
          events: {
            afterSetExtremes(e) {
              // Areaの場合のみyAxis.minが効かず0になってしまうので、minを明示的に適用させる
              if (this.chartType == "candlestick") {
                return
              }
              const option = {
                yAxis: {
                  min: this.chart.series[0].dataMin,
                }
              }
              this.chart.update(option, true);
            }
          }
        }],
        yAxis: [{
          labels: {
            align: 'left'
          },
          height: '80%',
          resize: {
            enabled: true
          },
          endOnTick: false,
          startOnTick: false,
          min: null,
          labels: {
            x: 0
          }
        }, {
          labels: {
            align: 'left'
          },
          min: null,
          top: '80%',
          height: '20%',
          labels: {
            x: 0
          }
        }],
        tooltip: {
          shared: true,
          split: false,
          useHTML: true,
          formatter() {
            let s = '<table><tr class="border-b border-gray-400"><th colspan="2">' + Highcharts.dateFormat('%Y/%m/%d (%a)', this.x) + '</th></tr>';
            this.points.forEach(point => {
              s += '<tr><td style="color: ' + point.series.color + '">' + point.series.name + '</td><td class="text-right">' + Math.round(point.y * 100) / 100 + '</td></tr>'
            });

            s += '</table>';

            return s;
          }
        },
        plotOptions: {
          series: {
            animation: false,
            dataGrouping: {
              enabled: this.chartType == "candlestick",
              dateTimeLabelFormats: {
                minute: ['%Y/%m/%d (%a)'],
                hour: ['%Y/%m/%d (%a)'],
                day: ['%Y/%m/%d (%a)'],
                week: ['%Y/%m/%d (%a)'],
                month: ['%Y/%m'],
                year: ['%Y'],
              },
            }
          }
        },
        series: [{
          type: this.chartType,
          id: this.ticker + '-' + this.chartType,
          name: this.name,
          data: this.ohlc,
          color: "#164E63",
          fillColor: {
            linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
            stops: [
              [0, "#94A3B8"],
              [1, "#ffff"],
            ]
          },
        }, {
          type: 'sma',
          name: "移動平均（25）",
          params: {
            period: 25,
          },
          linkedTo: this.ticker + "-" + this.chartType,
          market: {
            enabled: false
          },
          color: "#DC000C",
        }, {
          type: 'sma',
          name: "移動平均（75）",
          params: {
            period: 75,
          },
          linkedTo: this.ticker + "-" + this.chartType,
          market: {
            enabled: false
          },
          color: "#2563EB",
        }, {
          type: 'column',
          id: this.ticker + '-volume',
          name: '出来高',
          data: this.volume,
          yAxis: 1,
          color: "#6699CC",
        }],
        responsive: {
          rules: [{
            condition: {
              maxWidth: 800
            },
            chartOptions: {
              rangeSelector: {
                inputEnabled: false
              }
            }
          }]
        },
        credits: {
          enabled: false
        },
      }
    }
  }
}
</script>

<style scoped>
</style>
