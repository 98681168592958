<template>
  <div class="text-xs">
    <p @click="toggleSlide" class="flex gap-1 items-center cursor-pointer">
      ※決算短信AI要約に関する注意・免責事項
      <i :class="iconClasses" class="fa-solid"></i>
    </p>
    <ul id="sliding-element" :class="classes" class="pt-2 overflow-hidden text-slate-500 transition-all duration-500">
      <li>
        ・本サービスは、企業が開示した決算資料の要点をAIを使用してまとめたものを掲載しています。現在はβ版として運用しており、機能のテストおよびユーザーフィードバックの収集を目的としています。
      </li>
      <li>
        ・データ形式の問題やその他の技術的な制約により、決算短信の内容を全て網羅的に要約することができない場合があります。特に複雑な金融用語や業績の詳細な分析は、要約の精度が低下する可能性があります。
      </li>
      <li>
        ・可能な限り迅速に情報を提供するよう努めていますが、決算発表が集中する時期はシステムの処理能力の限界により、情報配信に遅延が生じることがあります。
      </li>
      <li>
        ・提供される情報は、Open AI 社が提供する生成AIサービスを活用して、一定のルールに従い生成されています。生成AIの性質上、提供される情報には誤りや虚偽の情報が含まれるおそれがあるほか、断定的な表現や投資を推奨するかのような表現が含まれるおそれがありますが、本サービスに基づき提供される情報は投資判断の参考として決算短信等に関する情報提供を目的とするものであり、投資の勧誘や投資判断を提供することを目的とするものではありません。
      </li>
      <li>
        ・提供されるすべての情報について、正確性、信頼性、完全性、有効性、適時性等は一切保証されておりません。これらの情報に基づいて被ったいかなる損害についても、当社および Open AI 社は一切の責任を負いません。投資に関するすべての決定は、利用者ご自身の判断でなさるようにお願いいたします。
      </li>
      <li>
        ・本サービスに掲載されている要約に関する権利は当社に帰属します。これらの情報を私的利用の範囲を超えて利用することはもちろん、第三者へ提供する目的で情報を転用、複製、販売、加工、再利用及び再配信することを固く禁じます。
      </li>
      <li>
        ・本サービスは予告なく機能の変更、提供の一時休止または中止をすることがあります。
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "SlidingDisclaimer",

  data() {
    return {
      isSlidDown: false,
    }
  },

  computed: {
    classes() {
      return this.isSlidDown ? "block" : "hidden h-0";
    },

    iconClasses() {
      return this.isSlidDown ? "fa-angle-up" : "fa-angle-down"
    }
  },

  methods: {
    toggleSlide() {
      this.isSlidDown = !this.isSlidDown;
    }
  }
}
</script>

<style scoped>
#sliding-element {
    transition: max-height 0.5s ease-in-out;
}
</style>
